const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = [];

const searchPageUrl = '/Search_ep_42-1.html';
const isNonFitmentOptionProduct = ![
  ...window.document.querySelectorAll('#divOptionsBlock .opt-label .label'),
].some((elem) => elem.innerText === 'Fitment');

export default {
  platform: 'shift4shop',
  searchPageUrl,
  SearchRequestDefaults: {
    pageSize: 36,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    isVehicleSelectionIsolated: true,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: 'body.category-page .category-products',
      template: 'MainContent',
    },
    {
      name: 'FacetPanelCategory',
      type: 'FacetPanel',
      location: { firstChildOf: 'body.category-page #leftBar' },
      template: 'FacetPanelContainer',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'Name': 'A to Z',
        'Name:desc': 'Z to A',
        'Price': 'Price: Ascending',
        'Price:desc': 'Price: Descending',
      },
    },
    {
      name: 'FacetPanel',
    },
    { name: 'SearchResult' },
    {
      name: 'SearchBox',
      location: { replace: '#FRAME_SEARCH form', class: 'cm_search-box-root_container' },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.site-header [data-target="#searchModal"]',
        wrapper: 'a',
        class: 'pull-right',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: { insertAfter: 'body.home-page .homepage-slider', class: 'container' },
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 650,
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { insertAfter: '.extrafieldsBlock' },
      template: 'fitmentSearch/verifyFitment',
      visibleIf: () => isNonFitmentOptionProduct,
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: 'body.listing-page #rTabs a[href="#tab-fitment"]',
      template: 'fitmentSearch/fitmentTableTab',
      visibleIf: () => isNonFitmentOptionProduct,
    },
    {
      name: 'FitmentTableTabMobile',
      type: 'FitmentTable',
      location: 'body.listing-page #rTabs .r-tabs-accordion-title a[href="#tab-fitment"]',
      template: 'fitmentSearch/fitmentTableTab',
      visibleIf: () => isNonFitmentOptionProduct,
    },
    {
      name: 'FitmentTable',
      location: 'body.listing-page #rTabs #tab-fitment',
      template: 'fitmentSearch/fitmentTable',
      visibleIf: () => isNonFitmentOptionProduct,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
